var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow-2xl md:shadow-none",class:[
    _vm.$style.component,
    ( _obj = {}, _obj[_vm.$style['show-mobile-sidebar']] = _vm.mobileSidebarActive, _obj[_vm.$style['hide-mobile-sidebar']] = !_vm.mobileSidebarActive, _obj ) ]},[_c('div',{staticClass:"z-10"},[_c('div',{staticClass:"flex md:hidden w-full p-1"},[_c('b-icon',{staticClass:"ml-auto",attrs:{"icon":"close"},nativeOn:{"click":function($event){return _vm.hideMobileSidebar($event)}}})],1),_vm._l((_vm.navItems),function(item){return _c('router-link',{key:item.label,attrs:{"to":item.to},nativeOn:{"click":function($event){return _vm.hideMobileSidebar($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var navigate = ref.navigate;
return [_c('div',[_c('div',{class:_vm.getClasses(item, 'root'),attrs:{"data-test":item.dataTest},on:{"click":navigate}},[_vm._v(" "+_vm._s(item.label)+" ")]),(_vm.shouldShowNestedRoutes(item))?_c('div',{staticClass:"flex flex-col"},_vm._l((item.children),function(nestedRoute){return _c('router-link',{key:nestedRoute.label,attrs:{"to":nestedRoute.to},nativeOn:{"click":function($event){return _vm.hideMobileSidebar($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var nestedNavigate = ref.navigate;
return [_c('div',{class:_vm.getClasses(nestedRoute, 'nested'),attrs:{"data-test":nestedRoute.dataTest},on:{"click":nestedNavigate}},[_vm._v(" "+_vm._s(nestedRoute.label)+" ")])]}}],null,true)})}),1):_vm._e()])]}}],null,true)})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }