

























































import { TOGGLE_SIDEBAR } from '@/graphql/query/platform'
import apolloClient from '@/services/apollo'
import Vue from 'vue'

interface Route {
  label: string
  to: string
  children?: Route[]
}

export default Vue.extend({
  name: 'Sidebar',
  data() {
    return {
      navItems: [
        {
          label: 'Home',
          to: '/',
          dataTest: 'home-link',
        },
        {
          label: 'Orders',
          to: '/orders',
          dataTest: 'orders-link',
          children: [
            {
              label: 'All Orders',
              to: '/orders',
              dataTest: 'orders-sublink',
            },
            {
              label: 'Drafts',
              to: '/drafts',
              dataTest: 'drafts-sublink',
            },
            {
              label: 'Checkouts',
              to: '/checkouts',
              dataTest: 'checkouts-sublink',
            },
          ],
        },
        {
          label: 'Reports',
          to: '/reports/dashboard',
          children: [
            {
              label: 'Dashboard',
              to: '/reports/dashboard',
            },
            {
              label: 'Sales over time',
              to: '/reports/sales',
            },
            {
              label: 'by product',
              to: '/reports/product',
            },
            {
              label: 'by product prices',
              to: '/reports/product-prices',
            },
            {
              label: 'by location',
              to: '/reports/location',
            },
            // {
            //   label: 'by customers',
            //   to: '/reports/customers',
            // },
            {
              label: 'by discount codes',
              to: '/reports/discounts',
            },
            {
              label: 'settings',
              to: '/reports/settings',
            },
          ],
        },
        {
          label: 'Fulfillment',
          to: '/fulfillment/groups',
        },
        {
          label: 'Products',
          to: '/products',
          dataTest: 'products-link',
        },
        {
          label: 'Discounts',
          to: '/discounts',
          dataTest: 'discounts-link',
        },
        {
          label: 'Customers',
          to: '/customers',
          dataTest: 'customers-link',
        },
        {
          label: 'Settings',
          to: '/settings',
          dataTest: 'settings-link',
        },
      ],
      mobileSidebarActive: false,
    }
  },
  async created() {
    apolloClient
      .watchQuery({
        query: TOGGLE_SIDEBAR,
      })
      .subscribe({
        next: ({ data }) => {
          this.mobileSidebarActive = data.isMobileSidebarOpen
        },
      })
  },
  methods: {
    shouldShowNestedRoutes(route: Route) {
      return (
        route.children &&
        route.children.find((nestedRoute) =>
          this.$route.path.includes(nestedRoute.to)
        )
      )
    },
    getClasses(link: Route, linkType: 'root' | 'nested') {
      const baseClasses = {
        'w-full': true,
        'px-4': true,
        'py-2': true,
        'text-sm': true,
        'cursor-pointer': true,
      }
      const rootNavItemClasses = {
        ...baseClasses,
        'text-black': true,
        'font-semibold': true,
        'bg-transparent': true,
        'hover:bg-gray-150': true,
      }
      const nestedNavItemClasses = {
        ...baseClasses,
        'text-gray-500': true,
        'font-medium': true,
        'hover:bg-gray-150': true,
      }

      const activeClasses = this.getActiveClasses(link)

      return {
        ...(linkType === 'root' && rootNavItemClasses),
        ...(linkType === 'nested' && nestedNavItemClasses),
        ...(activeClasses || {}),
      }
    },
    getActiveClasses(link: Route) {
      const isParentRoute = !!link.children // does this route have nested routes?

      if (isParentRoute) return

      const isSamePath = this.$route.path === link.to // is the current path the same as the link.to path

      // const isNestedRouteUnderLink =
      //   link.to !== '/' && this.$route.path.includes(link.to) // is the current path a "nested" route of the link
      const isNestedRouteUnderLink =
        link.to !== '/' && this.$route.path === link.to // is the current path a "nested" route of the link

      if (isSamePath || isNestedRouteUnderLink) {
        return {
          'border-solid': true,
          'border-l-2': true,
          'border-primary': true,
          'bg-gray-200': true,
          'hover:bg-gray-200': true,
          'text-primary': true,
          'hover:text-primary': true,
        }
      }
    },
    hideMobileSidebar() {
      apolloClient.writeQuery({
        query: TOGGLE_SIDEBAR,
        data: {
          isMobileSidebarOpen: false,
        },
      })
    },
  },
})
