






















import Vue from 'vue'
import Navbar from '@/components/shared/Navbar.vue'
import Sidebar from '@/components/shared/Sidebar.vue'

export default Vue.extend({
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
  },
  data() {
    return {
      preventScroll: false,
    }
  },
  computed: {
    overflowClasses() {
      if (this.preventScroll) {
        return {
          'overflow-hidden': true,
        }
      }

      return {
        'overflow-y-scroll': true,
      }
    },
  },
  methods: {
    handlePreventScroll(value: boolean) {
      if (value) {
        ;(this.$refs.section as HTMLElement).scrollTop = 0
      }

      this.preventScroll = value
    },
  },
})
