







































import apolloClient from '@/services/apollo'
import Vue from 'vue'
import { getCurrentUser, signOut } from '../../lib/user'
import { TOGGLE_SIDEBAR } from '@/graphql/query/platform'

export default Vue.extend({
  name: 'Navbar',
  data() {
    return {
      user: {} as Record<string, any>,
    }
  },
  computed: {
    fullName(): string {
      return this.user?.attributes?.name
    },
  },
  async created() {
    this.user = await getCurrentUser()
  },
  methods: {
    async signOut() {
      await signOut()
      this.$router.push('/login')
    },
    showMobileSidebar() {
      apolloClient.writeQuery({
        query: TOGGLE_SIDEBAR,
        data: {
          isMobileSidebarOpen: true,
        },
      })
    },
  },
})
